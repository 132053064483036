const paymentMessages = {
  'camp67-reservation.payment': 'Payment',
  'camp67-reservation.payment.tandc':
    'I agree to the <terms-of-service-radio>Terms of Service</terms-of-service-radio>',
  'camp67-reservation.payment.domestic-and-international':
    'For Indian & international customers.',
  'camp67-reservation.payment.international': 'For international customers.',
  'camp67-reservation.payment.provider-alt': 'Logo for the payment provider',
  'camp67-reservation.payment.continue': 'Continue to secure payment →',
};

export const allReservationMessages = {
  'camp67-reservation.book-with-us': 'Book your trip with us',
  'camp67-reservation.timeout.title':
    'You have 10 minutes remaining to secure your booking.',
  'camp67-reservation.timeout.description':
    'Please be advised that due to high booking demand, we cannot hold your booking for much longer.',
  'camp67-reservation.timeout.ok': 'Okay',
  'camp67-reservation.form.step.expand': 'Expand step',
  'camp67-reservation.form.step.collapse': 'Collapse step',
  'camp67-reservation.form.step-1': '<strong>Step 1/3:</strong> Choose your dates',
  'camp67-reservation.form.step-1.adult': 'Adult',
  'camp67-reservation.form.step-1.children': 'Children',
  'camp67-reservation.form.step-1.children-detail': 'Under the age of 18',
  'camp67-reservation.form.step-1.summary.dates': 'Dates of your stay',
  'camp67-reservation.form.step-1.summary.dates.empty-start':
    'Please select a start date',
  'camp67-reservation.form.step-1.summary.dates.empty-end': 'Please select an end date',
  'camp67-reservation.form.step-1.summary.dates.value': 'to', // when you translate this, note: you are translating the middle word of a string such as 13-Jan-2025 to 15-Jan-2025, the 'to'
  'camp67-reservation.form.step-1.summary.guests.empty': 'Please select number of guests',
  'camp67-reservation.form.step-1.summary.guests': 'Guests',
  'camp67-reservation.form.step.next': 'Next step',
  'camp67-reservation.form.step-1.next.tooltip.disabled': 'Please fill your dates first.',
  'camp67-reservation.form.step-2':
    '<strong>Step 2/3:</strong> Choose your tent experience',
  'camp67-reservation.form.step-2.tent-option.unavailable':
    'This room is unavailable during your selected dates, total guest count.',
  'camp67-reservation.form.step-2.tent-option.image-alt':
    'Displaying the tent experience offered under the {{type}} offering at camp67.',
  'camp67-reservation.form.step-2.summary.rooms': 'Your chosen room(s)',
  'camp67-reservation.form.step-2.summary.rooms.add.tooltip': 'Add 1',
  'camp67-reservation.form.step-2.summary.rooms.subtract.tooltip': 'Subtract 1',
  'camp67-reservation.form.step-2.summary.rooms.empty': 'Please select your room(s)',
  'camp67-reservation.form.step-2.next.tooltip.disabled':
    'Please select your rooms first.',
  'camp67-reservation.form.step-3': '<strong>Step 3/3:</strong> Enter your details',
  'camp67-reservation.form.step-3.guests': 'List of guests*',
  'camp67-reservation.form.step-3.disclaimer':
    'We require minimal details of all guests. View <payment-policy-link>our policy for more info.</payment-policy-link>',
  'camp67-reservation.form.step-3.additional-bed': 'Additional Bed',
  'camp67-reservation.form.step-3.guests.enter-name': 'Enter name...',
  'camp67-reservation.form.step-3.guests.enter-age': 'Enter age...',
  'camp67-reservation.form.step-3.gender-male': 'Male',
  'camp67-reservation.form.step-3.gender-female': 'Female',
  'camp67-reservation.form.step-3.gender-non-binary': 'Non-binary',
  'camp67-reservation.form.step-3.gender-none': 'None',
  'camp67-reservation.form.step-3.gender-prefer-not-to-say': 'Prefer not to say',
  'camp67-reservation.form.step-3.primary-contact': 'Primary Contact*',
  'camp67-reservation.form.step-3.primary-contact.inform':
    'Choose who to inform for booking updates. All data is <privacy-policy-link>securely handled.</privacy-policy-link>',
  'camp67-reservation.form.step-3.primary-contact.select': 'Select your primary contact',
  'camp67-reservation.form.step-3.primary-contact.email': 'Enter email address...',
  'camp67-reservation.form.step-3.primary-contact.phone': 'Enter phone number...',
  'camp67-reservation.form.step-3.summary.guests': 'Your guest details',
  'camp67-reservation.form.step-3.summary.guests.empty': 'Please enter guest details',
  'camp67-reservation.form.step-3.summary.primary': 'Primary Contact',
  'camp67-reservation.form.step-3.summary.primary.empty':
    'Please provide primary contact',
  'camp67-reservation.form.step-3.next.tooltip.disabled':
    'Please provide guest & contact details first.',
  'camp67-reservation.form.step.disabled.tooltip':
    'Please finish the previous step first.',
  'camp67-reservation.form.submit.bathing-added': 'added for bathing dates.',
  'camp67-reservation.form.submit.bathing-charged': 'charged for bathing dates.',
  'camp67-reservation.form.submit.night': 'Night',
  'camp67-reservation.form.submit.nights': 'Nights',
  'camp67-reservation.form.submit.room': 'Room',
  'camp67-reservation.form.submit.rooms': 'Rooms',
  'camp67-reservation.form.submit.empty': 'Fill in your details and dates first.',
  'camp67-reservation.form.submit.total': 'Total',
  'camp67-reservation.form.submit.price-shown-in': 'Price shown in',
  'camp67-reservation.form.submit.exchange-calculated':
    'Exchange rates are<br />calculated daily',
  ...paymentMessages,
};
